export const brandStageFeatureFlags = {
  featureFlags: {
    enableProjectTypes: false,
    supportCompanyOnJobPost: false,
    showCompanyOnJobPost: false,
    disableRateOnSignup: false,
    enableExperienceLevel: true,
    enablePublicCandidatePage: true,
    enableInviteToApply: true,
    enableRecruiterAssignment: true,
    showApplicantNotes: true,
    showScheduler: true,
    enableSuggestedCandidates: true,
  },
};

export const brandProdFeatureFlags = {
  featureFlags: {
    enableProjectTypes: true,
    supportCompanyOnJobPost: true,
    showCompanyOnJobPost: false,
    disableRateOnSignup: true,
    enableExperienceLevel: true,
    enablePublicCandidatePage: true,
    enableInviteToApply: true,
    enableRecruiterAssignment: false,
    showApplicantNotes: true,
    showScheduler: true,
    enableSuggestedCandidates: true,
  },
};

export default brandStageFeatureFlags;